/* APP-WIDE STYLES */

@font-face {
  font-family: 'Bitmgothic';
  src: url('./font/Bitmgothic.woff') format('woff');
}

:root {
  --color-green: #034513;
  --color-dark: #3b4e3b;
  --color-red: #68211b;
  --color-light: #ded3aa;
}

.App {
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  font-size: calc(10px + 2vmin);
  color: var(--color-light);
  font-size: 2vw;
}

.app-background {
  position: fixed;

  width: 100vw;
  height: 100vh;

  background-image: url(./img/flowersblack.png);
  z-index: -2;
}

*::selection {
  background: var(--color-green);
}

/* MAIN LAYOUT */

main {
  display: flex;
  justify-content: space-between;

  height: 100vh;
  width: 100vw;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-basis: 600px;
  flex-shrink: 3;

  height: 100vh;
  min-width: 30vw; /* So that main container doesn't flex too large during transitionstart */
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 600px;
  flex-shrink: 4;

  height: 100vh;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 1400px;
  flex-shrink: 1;

  max-width: 40vw; /* Or else it flexes too large in transition when board and rewards both momentarily there  */
  height: 100vh;
}

/* BOARD COMPONENT CLASSES */

.board-wrapper {
  display: inline-block;
  position: relative;

  width: 100%;
  max-width: 95vmin;
  min-width: 300px;
  margin: 20px;
}

.board {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  /* min-width: 500px; */
  /* min-height: 500px; */
}

.square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(8.33% - 3px);
  width: calc(8.33% - 3px);

  border: 1.5px solid black;
  transition: background-color 200ms ease-out;
}

.piece {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 75%;
  height: 75%;
}

.not-exhausted {
  cursor: grab;
}

.exhausted {
  opacity: 0.5;
}

.piece-symbol {
  position: relative;

  width: 100%;
  height: 100%;

  z-index: 2;
}

/* BENCH COMPONENT CLASSES */

.bench {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 12vh;
  height: 48vh;
  margin: 0 20px;

  z-index: 101;
}

.bench-square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 12.5%;
  width: 50%;

  outline: 2px solid var(--color-green);
  color: white;
}

.bench-piece {
  animation: fadeAndShrinkIn 300ms ease-out;
}

/* REWARDS COMPONENT CLASSES */

.rewards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
  height: 66vh;

  background-image: url(./img/flowersblue.png);
  background-attachment: fixed;
}

.pack {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  max-height: 90%;
  max-width: 90%;

  font-family: 'Cinzel Decorative', 'Bitmgothic', serif;
  color: var(--color-dark);
  font-size: 1.5vw;

  background-image: url(./img/flowersdullpink.png);
}

.unopened {
  width: 25%;
}

.unopened div {
  padding: 10%;
}

.unopened div:last-child {
  font-size: 3vw;
}

.unopened:hover {
  background-image: url(./img/flowersgold.png);
}

.opened {
  animation: fadeIn 500ms, glow 900ms ease-in-out infinite alternate;
}

.choice {
  display: flex;
  justify-content: center;
  align-items: center;

  max-height: 15vh;
  margin: 2rem;

  border: 1px solid black;
  background-image: url(./img/flowerspink.png);
}

.choice:hover {
  background-image: url(./img/flowersgold.png);
}

/* APP-LEVEL BUTTON CLASSES */

button {
  width: 20vw;
  min-height: 4vw;
  padding: 0;

  font-family: 'Bitmgothic', cursive;
  font-size: 2vw;
  color: black;

  background-image: url(./img/flowersbeige.png);
  cursor: pointer;
  transition: background-image 350ms, box-shadow 100ms;
  border: none;
}

button:hover {
  background-image: url(./img/flowersbeigeandred.png);
}

button:active {
  box-shadow: inset 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
}

button:focus {
  outline: none;
  box-shadow: inset 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
}

.clickable {
  cursor: pointer;
  transition: background-image 350ms, box-shadow 100ms;
}

.clickable:active {
  box-shadow: inset 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
}

.clickable:focus {
  outline: none;
  box-shadow: inset 10px 10px 20px 0px rgba(0, 0, 0, 0.75);
}

.readybutton {
  height: 10vh;
  width: 80%;
  margin: 4vh auto;

  background-image: url('./img/flowersbeige.png');
  color: black;
  animation: fadeIn 500ms;
  z-index: 100;
}

.pre-ready {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-family: 'Cinzel Decorative', serif;
  font-size: 1vw;
}

.ready-type {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.ready-num {
  font-family: 'Bitmgothic', serif;
  font-size: 1.5vw;
}

.enemy-capture {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: -1;
}

/* OTHER APP-LEVEL COMPONENT CLASSES*/

.scoreboard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  height: 20vh;
  width: 12vh;
  margin: 4vh 0;
  font-family: 'Bitmgothic', serif;

  background-image: url(./img/flowersgreyandgreen.png);
  color: black;
  background-attachment: fixed;
}

.scoreboard div:first-child {
  text-decoration: underline;
}

.scoreboard div:last-child {
  font-size: 150%;
}

.big-text {
  width: 20vw;

  font-size: 5vw;
  font-family: 'Cinzel Decorative', serif;
  color: var(--color-light);

  background: linear-gradient(90deg, var(--color-light), var(--color-green));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* FIXED COMPONENT CLASSES */

.corner-button {
  position: fixed;
  bottom: 0;

  margin: 1vh 1vw;

  text-decoration: none;
  color: var(--color-light);
  font-size: 1vw;
  font-family: 'Cinzel Decorative', serif;

  cursor: pointer;
}

.corner-left {
  left: 0;
}

.corner-right {
  right: 0;
}

.modal-background {
  position: fixed;
  top: 0px;
  left: 0px;

  height: 100vh;
  width: 100vw;

  background-color: rgb(0, 0, 0, 0.5);
  z-index: 103;
  cursor: auto;
}

.mobile-modal {
  display: none;
}

/* MODAL COMPONENT CLASSES */

.modal {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  height: 60vh;
  min-height: 300px;
  width: 60vh;
  min-width: 300px;
  margin: 20vh auto auto auto;

  color: black;
  font-size: 1.5rem;
  font-family: 'Bitmgothic', serif;

  background-image: url('./img/flowersgreyandgreen.png');
  border-radius: 4px;
}

.enemy-modal {
  font-size: 2.5rem;

  background-image: url('./img/flowersgreyandred.png');
  color: whitesmoke;
}

.gameover-modal {
  font-size: 150%;
  color: var(--color-light);

  background-image: url('./img/flowersblack.png');
}

.help-modal {
  color: white;
  font-family: 'Cinzel Decorative', serif;

  background-color: rgb(0, 0, 0, 0.8);
}

.modal-banner {
  display: flex;

  width: 60%;
  height: 2rem;

  font-size: 1.2rem;
}

.modal-name {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;

  background-color: var(--color-green);
}

.modal-lvl {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;

  background-color: var(--color-dark);
}

.modal-board {
  display: flex;
  flex-wrap: wrap-reverse; /* Necessary or else move previews are reflected... sorry */

  height: 60%;
  width: 60%;

  transform: rotate(90deg);
}

.modal-movetypes {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  font-size: 0.75rem;
}

.modal-movetype {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60%;
  height: 1rem;

  text-align: center;
}

.modal-square {
  height: 1.5vh;
  width: 1.5vh;
  margin-right: 1.5vh;
}

/* REPEATED/WRAPPER CLASSES */

.pulse {
  animation: pulse 2s ease-in-out;
  animation-iteration-count: infinite;
}

.wrapper-inner {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;

  background-image: url('./img/flowersblack.png');
}

.wrapper-outer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;
  margin: 0 20px;

  background-image: url('./img/flowersbeige.png');
}

.transition-wrapper {
  display: flex;
  justify-content: center;

  width: 100%;
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.color-light {
  background-color: var(--color-light);
}

.color-dark {
  background-color: var(--color-dark);
}

.color-red {
  background-color: var(--color-red);
}

.color-green {
  background-color: var(--color-green);
}

/* ANIMATIONS */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutAndWait {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeAndShrinkIn {
  0% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeAndGrowOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes slideDownIn {
  0% {
    transform: translate(0, -1000px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes slideUpIn {
  0% {
    transform: translate(0, 1000px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes slideDownOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 1000px);
  }
}

@keyframes slideUpOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -1000px);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #fff, 0 0 15px #d6e292, 0 0 25px #d6e292, 0 0 35px #d6e292;
  }
  100% {
    box-shadow: 0 0 10px #fff, 0 0 20px #eef1bf, 0 0 30px #eef1bf, 0 0 40px #eef1bf;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes fadeOutAndGlow {
  0% {
    opacity: 1;
  }
  35% {
    box-shadow: 0 0 5px #fff, 0 0 15px #d6e292, 0 0 25px #d6e292, 0 0 35px #d6e292;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 10px #fff, 0 0 20px #eef1bf, 0 0 30px #eef1bf, 0 0 40px #eef1bf;
  }
  75% {
    opacity: 0;
    box-shadow: 0 0 5px #fff, 0 0 15px #d6e292, 0 0 25px #d6e292, 0 0 35px #d6e292;
  }
  100% {
    box-shadow: none;
  }
}

@keyframes collapseOut {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-90deg);
  }
}

@keyframes collapseIn {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* MEDIA QUERIES */

@media (max-width: 1250px) {
  .ready-rarity {
    display: none;
  }
}

@media (max-width: 520px) {
  .disappear {
    display: none;
  }
}

@media (max-width: 700px) {
  main {
    flex-direction: column;
  }
  .left-container {
    flex-direction: row;

    height: 30vh;
    width: 100vw;
    min-height: 20vh;
  }
  .right-container {
    flex-direction: row;

    height: 30vh;
    width: 100vw;
  }
  .main-container {
    height: 40vh;
    width: 100vw;
    min-width: 100vw;
  }

  .bench {
    flex-direction: row;

    width: 48vw;
    height: 12vw;
    margin: 20px 0;
  }

  .bench-square {
    width: 12.5%;
    height: 50%;
  }

  .scoreboard {
    height: 12vw;
    width: 12%;
    margin: 0 5vw;
  }

  .readybutton {
    margin: 0 5vw;
  }

  .ready-num {
    font-size: 2vw;
  }

  .board-wrapper {
    width: 60vmin;
    margin: 40px;
    min-width: 200px;
  }

  .rewards {
    width: 75%;
    height: 50vh;
  }

  .choice {
    margin: 1rem;
  }

  .wrapper-outer {
    margin: 10px 0;
  }
}

@media only screen and (max-device-width: 800px) {
  .mobile-modal {
    display: inline;
    text-align: center;
    padding-top: 30vh;
    font-size: 7vw;
    z-index: 102;
  }
}
